import { required, email, regex } from 'react-admin';

export const validateRequired = [required()];
export const validatePercentage = [required(), regex(/\b(?<!\.)(?!0+(?:\.0+)?%)(?:\d|[1-9]\d|100)(?:(?<!100)\.\d+)?%/, "Adjon meg egy százalékos értéket.")];
export const validateVersion = [regex(/^\d+\.\d+\.\d+$/, "Adjon meg egy verziószámot. Pl. 1.2.22")];
export const validateDate = [regex(/^\d\d\d\d\.\d\d\.\d\d$/, "Adjon meg egy dátumot. Pl. 2018.01.01")];
export const validateRequiredDate = [required(), regex(/^\d\d\d\d\.\d\d\.\d\d$/, "Adjon meg egy dátumot. Pl. 2018.01.01")];
// eslint-disable-next-line
export const validateEmails = [regex(/^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/, "Adjon meg email címeket ;-vel elválasztva")];
export const validateRequiredEmail = [required(), email("Adjon meg egy email címet")];
export const validateEmail = [email("Adjon meg egy email címet")];
export const validateTime = [required(), regex(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, "Adjon meg egy időpontot. Pl. 07:10")];

