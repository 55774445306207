import React, { useCallback } from 'react';
import {
  GoogleMap,
  Marker,
  LoadScript
} from '@react-google-maps/api';
import { getConfig } from '../config';

const apiKey = `${getConfig().GOOGLE_MAPS_API_KEY}`;

const LocationPicker = ({ defaultPosition, onChange }) => {

  const onMapClick = useCallback((event) => {

    const position = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    };

    // Use Geocoding API to get the address
    const geocoder = new window.google.maps.Geocoder();
    let address = '';
    let places = '';
    geocoder.geocode({ location: position, language: 'hu' }, (results, status) => {
      if (status === 'OK' && results[0]) {
        address = results[0].formatted_address;
        places = results;
      } else {
        console.error('Geocode was not successful for the following reason:', status);
      }
      if (onChange) {
        onChange({position, address, places});
      }
    });
  }, [onChange]);

  return (
  <LoadScript googleMapsApiKey={apiKey}>
    <GoogleMap
      mapContainerStyle={{ height: '500px', width: '100%' }}
      center={defaultPosition}
      zoom={14.7}
      onClick={onMapClick}
      gestureHandling='cooperative'
    >
      <Marker position={defaultPosition} />
    </GoogleMap>
  </LoadScript>
  );
};

export default LocationPicker;
