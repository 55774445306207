import React from 'react';
// eslint-disable-next-line
import { TextInput, List, Datagrid, TextField, Filter, ReferenceInput, SelectInput, Pagination } from 'react-admin';
// eslint-disable-next-line
import MyTextField from './MyTextField';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import MyIcon from '@material-ui/icons/ScreenShare';
export const DevicelogIcon = MyIcon;

/*
MomentUtils.dateTime12hFormat = 'YYYY.MM.DD hh:mm a';
MomentUtils.dateTime24hFormat = 'YYYY.MM.DD HH:mm';
MomentUtils.time12hFormat = 'hh:mm A';
MomentUtils.time24hFormat = 'HH:mm';
MomentUtils.dateFormat = 'YYYY.MM.DD';
*/

const choicesSzint = [
    { id: 'INFO', name: 'INFO' },
    { id: 'DEBUG', name: 'DEBUG' },
    { id: 'WARN', name: 'WARN' },
    { id: 'ERROR', name: 'ERROR' },
];

const devicelogFilterStyles = {
    form: {
        width: '100%',
        '& [data-source=q]': {
            'flex-basis': '100%',
            '& div': {
                width: '100%'
            }
        } 
    },
};


const DevicelogFilter = withStyles(devicelogFilterStyles)(({classes, ...props}) => (    
    <Filter classes={classes} {...props}>
        <TextInput 
            source="from" 
            alwaysOn
            allowEmpty
            label="resources.devicelog.filter.from"
        />               
        <TextInput source="fromhour" alwaysOn allowEmpty label="resources.devicelog.filter.fromhour" />
        <TextInput 
            source="to" 
            alwaysOn
            allowEmpty
            label="resources.devicelog.filter.to"
        />               
        <TextInput source="tohour" alwaysOn allowEmpty label="resources.devicelog.filter.tohour"/>
        <SelectInput source="level" choices={choicesSzint} optionText="name" optionValue="id" alwaysOn allowEmpty />        
        <TextInput source="email" alwaysOn allowEmpty label="resources.devicelog.filter.email"/>
        <TextInput label="resources.devicelog.filter.search" source="q" alwaysOn allowEmpty />
    </Filter>
));

const devicelogListStyles = {
    root: {
        '& table tbody': {
            backgroundColor: 'black' 
        },
        '& .column-message': {
            'word-break': 'break-all',
            'whiteSpace': 'pre-wrap',
        }
    },
}

const DevicelogPagination = props => <Pagination rowsPerPageOptions={[100, 250, 500, 1000]} {...props} />

export const DevicelogList = withStyles(devicelogListStyles)(({ classes, ...props }) => (
    <List 
        classes={classes}
        {...props}
        bulkActionButtons={false} 
        pagination={<DevicelogPagination/>}
        exporter={false}
        perPage={500} 
        sort={{ field: 'timestamp', order: 'DESC' }} 
        filters={<DevicelogFilter />} 
        filterDefaultValues={{ from: moment().format('YYYY.MM.DD'), level: 'INFO' }}
        >
        <Datagrid>
			<MyTextField source='email' isTranslated={false} color="color" sortable={false} />
            <MyTextField source="appVersion" isTranslated={false} sortable={false} color="color" />
			<MyTextField source='timestamp' isTranslated={false} color="color" render={record => moment.utc(record.timestamp).local().format('YYYY.MM.DD.[&nbsp;]HH:mm:ss.SSS') } sortable />
            <MyTextField source="level" color="color" isTranslated={false} sortable={false} />
            <MyTextField source="message" isTranslated={false} color="color" sortable={false} />
        </Datagrid>
    </List>
));
