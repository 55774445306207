import React, { createElement } from 'react';
import { connect } from 'react-redux';
import { humanize, pluralize } from 'inflection';
import { MenuItemLink, getResources, translate } from 'react-admin';
import { withRouter } from 'react-router-dom';

const translatedResourceName = (resource, translate) => {
    return translate('resources.' + resource.name + '.name', {
        smart_count: 2,
        _: resource.options && resource.options.label ? translate(resource.options.label, {
            smart_count: 2,
            _: resource.options.label
        }) : humanize(pluralize(resource.name))
    });
}; 

const Menu = ({ resources, onMenuClick, logout, translate }) => (
    <div>
        {
            resources.filter(function (r) {
                return r.hasCreate || r.hasList || r.name==='config';
            }).map((resource) => {
                let link;
                if (resource.name === 'config') {
                    link = '/' + resource.name + '/1';
                } else {
                    if (!resource.hasList && resource.hasCreate) {
                        link = '/' + resource.name + '/create';                    
                    } else {
                        link = '/' + resource.name;
                    }
                }
                return (
                    <MenuItemLink
                        to={link} 
                        primaryText={translatedResourceName(resource, translate)} 
                        leftIcon={createElement(resource.icon)}
                        onClick={onMenuClick}
                        key={resource.name}
                    />
                );
            })
        }
        {logout}
    </div>
);

const mapStateToProps = state => ({
    resources: getResources(state),
});

export default withRouter(translate(connect(mapStateToProps)(Menu)));

