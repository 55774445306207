import React from 'react';
import { Admin, Resource } from 'react-admin';

import { getConfig } from './config';
import authProvider from './authProvider';
import restClient from './restClient';
import MyLoginPage from './components/Login';
import Menu from './menu';
import { UserList, UserIcon, UserCreate, UserEdit } from './users';
import { GroupList, GroupIcon, GroupCreate, GroupEdit } from './groups';
import { ConfigurationIcon, ConfigurationEdit } from './configuration';
import { AdatFrissitesIcon, AdatFrissitesList, AdatFrissitesEdit } from './adatfrissites';
import { BejelentesTipusList, BejelentesTipusIcon, BejelentesTipusCreate, BejelentesTipusEdit } from './bejelentesTipus';
import { LatnivaloList, LatnivaloIcon, LatnivaloCreate, LatnivaloEdit } from './latnivalo';
import { NaploIcon, NaploList } from './naplo';
import { DevicelogIcon, DevicelogList } from './devicelog';

import hungarianMessages from './hungarian';
import moment from 'moment';
import 'moment/locale/hu';

import './App.css';

const messages = {
    'en': hungarianMessages,
    'hu': hungarianMessages,
};

const i18nProvider = locale => messages[locale];

class App extends React.Component {
  constructor(props) {
    super(props);
    moment.locale('hu');
  }

  componentWillMount() {
    console.log("App.componentWillMount " + window.location);
    console.log("config", getConfig());
    console.log("env", process.env);		
  }

  render() {
    console.log("App.render " + window.location);
    return (
      <Admin title="Sándorfalva Admin" 
            menu={Menu} 
            locale="hu" 
            i18nProvider={i18nProvider} 
            dataProvider={restClient} 
            loginPage={MyLoginPage} 
            authProvider={authProvider}
        >
        <Resource name="user" list={UserList} create={UserCreate} edit={UserEdit} icon={UserIcon} />    	
        <Resource name="group" list={GroupList} create={GroupCreate} edit={GroupEdit} icon={GroupIcon} />    	
        <Resource name="permission" />   	
        <Resource name="config" icon={ConfigurationIcon} edit={ConfigurationEdit} />
        <Resource name="adatFrissitesBeallitas" icon={AdatFrissitesIcon} list={AdatFrissitesList}  edit={AdatFrissitesEdit}/>
        <Resource name="naplo" icon={NaploIcon} list={NaploList} />
        <Resource name="devicelog" icon={DevicelogIcon} list={DevicelogList} />
        <Resource name="bejelentesTipus" list={BejelentesTipusList} create={BejelentesTipusCreate} edit={BejelentesTipusEdit} icon={BejelentesTipusIcon} />    	
        <Resource name="latnivalo" list={LatnivaloList} create={LatnivaloCreate} edit={LatnivaloEdit} icon={LatnivaloIcon} />    	
      </Admin>
    );
  }
}

export default App;