import React from 'react';
// eslint-disable-next-line
import { TextInput, List, Datagrid, TextField, Filter, SelectInput, Pagination } from 'react-admin';
import MyIcon from '@material-ui/icons/Storage';
// eslint-disable-next-line
import MyTextField from './MyTextField';
import { withStyles } from '@material-ui/core/styles';

export const NaploIcon = MyIcon;

const choicesKategoria = [
    { id: 'DELTACOMPRESS', name: 'Delta tömörítés' },
    { id: 'CACHE', name: 'Gyorsítótár' },
    { id: 'CLEANUP', name: 'Takarítás' },
    { id: 'EMAIL', name: 'Email küldés' },
    { id: 'ADATMIGRACIO', name: 'Adatmigráció' },
    { id: 'KOZTERULETIBEJELENTES', name: 'Közterületi bejelentés' },
 ];

 const choicesSzint = [
    { id: 'INFO', name: 'INFO' },
    { id: 'WARNING', name: 'FIGYELMEZTETÉS' },
    { id: 'ERROR', name: 'HIBA' },
 ];

 const styles = {
    search: {
        width: '75%', 
    },
    form: {
        width: '100%',
        '& [data-source=q]': {
            'flex-basis': '70%',
        } 
    },
};

const NaploFilter = withStyles(styles)(({classes, ...props}) => (    
    <Filter classes={classes} {...props}>
        <SelectInput source="szint" choices={choicesSzint} optionText="name" optionValue="id" alwaysOn allowEmpty/>
        <SelectInput source="kategoria" choices={choicesKategoria} optionText="name" optionValue="id" alwaysOn allowEmpty/>
        <TextInput className={classes.search} label="resources.search" source="q" alwaysOn allowEmpty />
    </Filter>
));

const NaploPagination = props => <Pagination rowsPerPageOptions={[100, 250, 500, 1000]} {...props} />

export const NaploList = (props) => (
    <List {...props}  
        pagination={<NaploPagination/>}
        bulkActionButtons={false} 
        exporter={false} 
        perPage={100} 
        sort={{ field: 'datum', order: 'DESC' }} 
        filters={<NaploFilter />}>
        <Datagrid>
            <TextField source="datum" sortable={true}/>
            <MyTextField source="szint" sortable={true}/>
            <MyTextField source="kategoria" sortable={true} isTranslated={true} />
            <MyTextField source="uzenet" sortable={true} isTranslated={false} />
        </Datagrid>
    </List>
);
