import React from 'react';
// eslint-disable-next-line
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, translate, Pagination } from 'react-admin';
// eslint-disable-next-line
import { Create, List, Edit, Datagrid, TextField, EditButton, EmailField, ReferenceField, FormDataConsumer, BooleanField, SingleFieldList, FunctionField , TextInput, SimpleForm, SelectArrayInput, SelectInput, ReferenceInput, BooleanInput, DisabledInput, DeleteButton } from 'react-admin';
import MyIcon from '@material-ui/icons/Person';
import ChipListField from './ChipListField'
import moment from 'moment';

export const UserIcon = MyIcon;

export const choices = [
    { id: 'ADMIN', name: 'resources.roles.admin' },
    { id: 'USER', name: 'resources.roles.user' },
    { id: 'APIDOC', name: 'resources.roles.apidoc' },
];

const UserPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500]} {...props} />

const validateUsername = [required(), minLength(5), maxLength(40)];
const validateEmail = [required(), email()];
const validateRequired = [required()];

export const UserList = (props) => (
    <List {...props} bulkActionButtons={false} exporter={false} pagination={<UserPagination/>} perPage={50}>
        <Datagrid>
            <TextField source="username" />
            <EmailField source="email" />
            <ChipListField source="roles" translateChoice />
            <ReferenceField perPage={1000} reference="group" source="groupId" linkType={false} allowEmpty>
                <ChipListField source="name" translatePrefix="" />
            </ReferenceField>
            <BooleanField source="enabled" headerstyle={{ textAlign: 'center' }} />
            <BooleanField source="test" headerstyle={{ textAlign: 'center' }} />
            <FunctionField source="accessedOn" render={ x => x.accessedOn ? moment(x.accessedOn).fromNow() : 'soha' } />
            <TextField source="clientVersion" />
            <EditButton source="" />
      </Datagrid>
    </List>
);

const userDefaultValue = { enabled: true, password: '' };

export const UserCreate = (props) => (
    <Create {...props}>
        <SimpleForm defaultValue={userDefaultValue}>
            <TextInput source="username" validate={validateUsername} />
            <TextInput source="email" type="email" validate={validateEmail} />
            <TextInput source="password" type="password" />
            <SelectArrayInput source="roles" validate={validateRequired} optionText="name" optionValue="id" choices={choices} />
            <ReferenceInput perPage={1000} reference="group" source="groupId" allowEmpty>
                <SelectInput optionText="name" />
            </ReferenceInput>
            <BooleanInput source="enabled" />
            <BooleanInput source="test" defaultValue={false} />
        </SimpleForm>
    </Create>
);

export const UserEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <DisabledInput source="id" />
            <TextInput source="username" validate={validateUsername} />
            <TextInput source="email" type="email" validate={validateEmail} />
            <TextInput source="password" type="password" />
            <SelectArrayInput source="roles" validate={validateRequired} optionText="name" optionValue="id" choices={choices} />
            <ReferenceInput perPage={1000} reference="group" source="groupId" allowEmpty>
                <SelectInput optionText="name" />
            </ReferenceInput>
            <BooleanInput source="enabled" />
            <BooleanInput source="test" defaultValue={false} />
        </SimpleForm>
    </Edit>
);