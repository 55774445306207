import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_GET_PERMISSIONS , AUTH_CHECK } from 'react-admin';
import { getFirebase } from './config';

export default (type, params) => {
    const firebase = getFirebase();

    if (type === AUTH_LOGIN) {
        const { username, password } = params;
        return firebase.auth().signInWithEmailAndPassword(username.trim(), password)
            .then(userCredential => {
                const { user } = userCredential;
                return user.getIdToken().then((idToken) => {
                    localStorage.setItem('token', idToken);
                    return Promise.resolve();
                });                  
            })
            .catch(error => {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = 'auth.error.' + errorCode.replaceAll('/','_').replaceAll('-','_');
                return Promise.reject(errorMessage);
            });
    }
    if (type === AUTH_LOGOUT) {
    	localStorage.removeItem('token');
        return firebase.auth().signOut().then(() => Promise.resolve());
    }
    if (type === AUTH_ERROR) {
        const status  = params.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();    
	}
    if (type === AUTH_CHECK) {
    	const token = localStorage.getItem('token');    	    	
		if (token) {
		 	return Promise.resolve();
		} else {
		 	return Promise.reject({ redirectTo: '/login' });
		}
    } 
    if (type === AUTH_GET_PERMISSIONS) {
        return Promise.resolve();
    } 
    
    return Promise.reject("Unhandled method");   
}