import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { translate } from 'react-admin';
import { pure, compose } from 'recompose';
import _ from 'lodash';

const sanitizeRestProps = ({
    addLabel,
    allowEmpty,
    basePath,
    cellClassName,
    className,
    formClassName,
    headerClassName,
    label,
    linkType,
    locale,
    record,
    resource,
    sortable,
    sortBy,
    source,
    textAlign,
    translateChoice,
    translatePrefix,
    ...rest
}) => rest; 

const styles = {
    chip: { margin: 4 },
};

export const ChipListField = ({
    className,
    translate,
    translateChoice,
    classes = {},
    source,
    translatePrefix = null,
    record = {},
    ...rest
}) => {
    let list = get(record, source);
    if (!list) {
        return <div/>;
    }
    if (!_.isArray(list)) {
        list = [ list ];
    }

    //const { resource } = rest;
    //console.log(list, rest, record);
    /*
    var resourceName = translate("resources." + resource + ".name", {
        smart_count: 2,
        _: inflection.humanize(inflection.pluralize(resource)),
    });
    var defaultTitle = translate('ra.page.list', {
        name: "" + resourceName,
    });
    */
    return (
        list.map(x => {
            let key = null;
            if (translatePrefix == null) {
                key = 'resources.' + source + '.' + x.toLowerCase();
            } else {
                key = translatePrefix + x.toLowerCase();
            }
            return (
                <Chip
                    className={classnames(classes.chip, className)}
                    label={translateChoice ? (key ? translate(key, { _: key }) : x ) : x }
                    key={x}
                    {...sanitizeRestProps(rest)}
                />
            );
        })
    );
};

ChipListField.contextTypes = {
    translate: PropTypes.func,
};

ChipListField.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    elStyle: PropTypes.object,
    sortBy: PropTypes.string,
    source: PropTypes.string.isRequired,
    record: PropTypes.object,
    translateChoice: PropTypes.bool.isRequired,
};

// wat? TypeScript looses the displayName if we don't set it explicitly
ChipListField.displayName = 'ChipListField';

ChipListField.defaultProps = {
    classes: {},
    translate: x => x,
    translateChoice: true,
};

const PureChipListField = compose(
    pure,
    withStyles(styles),
    translate,
)(ChipListField);

PureChipListField.defaultProps = {
    addLabel: true,
};

export default PureChipListField; 