import React, { Fragment } from 'react';
// eslint-disable-next-line
import { required, Pagination } from 'react-admin';
// eslint-disable-next-line
import { REDUX_FORM_NAME, Button, FileField, Create, List, Edit, LongTextInput, Datagrid, DisabledInput, TextField, FormDataConsumer, TextInput, SimpleForm, Toolbar, DeleteButton, SaveButton, FileInput, EditButton } from 'react-admin';
import MyIcon from '@material-ui/icons/Photo';
import { change } from 'redux-form';
import { validateRequired } from './validators';
import _ from 'lodash';
import LocationPicker from './components/LocationPicker';

export const LatnivaloIcon = MyIcon;

const defaultPosition = {
    lat: 46.365223,
    lng: 20.1004323
};

const MyPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />

const Img = (props) => {
    const data = _.get(props, "record." + props.source, null);
    if (data) {
        if (_.isArray(data)) {
            return  <Fragment>
                { _.map(data, (x, i) => (<img key={i} className="latnivalo-thumb" alt={x.title} src={"data:" + (x.type ? x.type : "image/jpeg") + ";base64," + x.data} />)) }
            </Fragment>
        } else {
            return <img className="latnivalo-thumb" alt={data.title} src={"data:" + (data.type ? data.type : "image/jpeg") +";base64," + data} />;
        }
    } else {
        return null;
    }
}

const ImagePreview = (props) => {
    const data = _.get(props, "record.data", null);
    const type = _.get(props, "record.type", "image/jpeg");
    const preview = _.get(props, "record.rawFile.preview", null);
    const title = _.get(props, "record.title", null);
    let src =  _.get(props, "record.src", null);
    if (preview) {
        src = preview;
    } else if (data) {
        src = "data:" + type + ";base64," + data;
    }
    if (src) {
        return (
            <img className="latnivalo-thumb" alt={title} src={src} />
        );
    } else {
        return null;
    }
}

const AudioPreview = (props) => {
    const data = _.get(props, "record.data", null);
    const type = _.get(props, "record.type", "image/jpeg");
    const preview = _.get(props, "record.rawFile.preview", null);
    const title = _.get(props, "record.title", null);
    let src =  _.get(props, "record.src", null);
    if (preview) {
        src = preview;
    } else if (data) {
        src = "data:" + type + ";base64," + data;
    }
    if (src) {
        return (
            <audio controls>
                <source src={src} type={type} alt={title} />
                Your browser does not support the audio element.
            </audio>
        );
    } else {
        return null;
    }
}


const TerkepButton = (props) => {
    return (<Button label="resources.latnivalo.map" onClick={ (record) => { window.open("https://www.google.com/maps/search/?api=1&query="+props.record.pozicio.latitude+","+props.record.pozicio.longitude); }} />);
}

export const LatnivaloList = (props) => (
    <div className="Latnivalo">
        <List {...props}
            bulkActionButtons={false}
            exporter={false}
            pagination={<MyPagination/>}
            perPage={25}
        >
            <Datagrid>
                <TextField source="nev" />
                <TextField source="cim" />
                <TextField source="leiras" />
                <Img source="kepek" />
                <TerkepButton />
                <EditButton source="buttons" />
                <DeleteButton />
            </Datagrid>
        </List>
    </div>
);


const CreateOrEdit = (props) =>
    <Fragment>
        <TextInput className="latnivalo-cim" source="nev" validate={validateRequired} />
        <LongTextInput source="leiras" />
        <FileInput {...props} source="kepek" multiple accept="image/*" validate={validateRequired}>
            <ImagePreview {...props} />
        </FileInput>
        <FileInput {...props} source="hang" accept="audio/*">
            <AudioPreview {...props} />
        </FileInput>
        <DisabledInput validate={validateRequired} source="pozicio.latitude" label="resources.latnivalo.fields.pozicio_latitude"/>
        <DisabledInput validate={validateRequired} source="pozicio.longitude" label="resources.latnivalo.fields.pozicio_longitude" />
        <TextInput className="latnivalo-cim" source="cim" validate={validateRequired}/>
        <FormDataConsumer>
            {({ formData, dispatch, ...rest }) => {
                    let pos = defaultPosition;
                    if (formData && formData.pozicio) {
                        pos = {
                            lat: Number(formData.pozicio.latitude),
                            lng: Number(formData.pozicio.longitude)
                        };
                    }
                    return (<Fragment>
                        <LocationPicker
                            defaultPosition={pos}
                            onChange={
                                ({ position, address, places }) => {
                                    console.log("loc change", position, address, places);
                                    const pozicio = {
                                            latitude: position.lat,
                                            longitude: position.lng,
                                    };
                                    dispatch(change(REDUX_FORM_NAME, 'pozicio' , pozicio));
                                    if (address !== '') {
                                        dispatch(change(REDUX_FORM_NAME, 'cim', address));
                                    }
                                }
                            }
                        />
                    </Fragment>);
                }
            }
        </FormDataConsumer>
    </Fragment>

const MyToolbar = ({undoable, ...props}) => (<Toolbar {...props}>
    <SaveButton  />
</Toolbar>);

const validateForm = (values) => {
    const errors = {};
    if (!values.kepek || values.kepek.length === 0) {
        errors.kepek=['ra.validation.filerequired'];
    }
    return errors;
};

export const LatnivaloCreate = (props) => (
    <Create undoable={false} {...props}>
        <SimpleForm redirect="list" validate={validateForm}>
            <CreateOrEdit />
        </SimpleForm>
    </Create>
);

export const LatnivaloEdit = (props) => (
    <Edit undoable={false} {...props}>
        <SimpleForm toolbar={<MyToolbar />}  validate={validateForm}>
            <CreateOrEdit />
        </SimpleForm>
    </Edit>
);