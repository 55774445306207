import React from 'react';
import { SelectArrayInput } from 'react-admin';
import { withStyles, createStyles } from '@material-ui/core/styles';

const styles = theme => createStyles({
    root: {
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
    },
    chip: {
        margin: theme.spacing.unit / 4,
    },
    select: {
        height: 'auto',
        overflow: 'auto',
    },
});

export default withStyles(styles)(({classes, ...props}) => {
    return (<SelectArrayInput {...props} translateChoice={true} classes={classes}/>);
});