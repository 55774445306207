import { getConfig } from './config';
import springServerRestClient from './springServerRestClient';
import { fetchUtils } from 'react-admin';
import moment from 'moment';
import _ from 'lodash';

export const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
}

const convertFileToBase64 = file => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(file.rawFile);

	reader.onload = () => {
		let result = "" + reader.result;
		let base64 = result.substr(result.indexOf(',') + 1);
		resolve(base64);
	}
	reader.onerror = reject;
});

const addUploadCapabilities = requestHandler => (type, resource, params) => {
	if (params.filter) {
		if (params.filter.from) {
			if (!moment(params.filter.from, 'YYYY.MM.DD', true).isValid()) {
				delete params.filter.from;
			}
		}
		if (params.filter.to) {
			if (!moment(params.filter.to, 'YYYY.MM.DD', true).isValid()) {
				delete params.filter.to;
			}
		}
	}

	if (['UPDATE', 'CREATE'].includes(type)) {
		let promise = Promise.resolve({...params.data});
		_.each(_.keysIn(params.data), key => {
			const item = params.data[key];
			if (item) {
				if (_.isArray(item)) {
					_.each(item, (x, i) => {
						if (x.rawFile) {
							promise = promise.then(data => {
								return convertFileToBase64(x)				
								.then(transformedFile => {
									x.name = _.get(x, "rawFile.name", null);
									x.title = _.get(x, "title", null);
									x.size = _.get(x, "rawFile.size", null);
									x.type = _.get(x, "rawFile.type", null);
									x.data = transformedFile;
									return data;
								});	
							});		
							}
					});
				} else {
					if (item.rawFile) {
						promise = promise.then(data => {
							return convertFileToBase64(item)				
							.then(transformedFile => {
								item.name = _.get(item, "rawFile.name", null);
								item.title = _.get(item, "title", null);
								item.size = _.get(item, "rawFile.size", null);
								item.type = _.get(item, "rawFile.type", null);
								item.data = transformedFile;
								return data;
							});	
						});		
					}
				}	
			}
		});
		return promise.then(data => {
			const newParams = {
				...params,
				data: data,
			};
			return requestHandler(type, resource, newParams);
		});
	}

	if (type === 'GET_LIST' && ['devicelog'].includes(resource) && params.filter.userid ) {
		return Promise.resolve().then(
			() => requestHandler('GET_ONE', 'user', { id: params.filter.userid })
		).then((response) => {
			const newParams = { ...params };
			newParams.filter.username = response.data.username;
			delete newParams.filter.userid; 
			return requestHandler(type, resource, newParams);
		});
	}

	return requestHandler(type, resource, params);
};
	
const restClient = addUploadCapabilities(springServerRestClient(getConfig().API_URL, httpClient));

export default restClient;