import { stringify } from 'query-string';
import { fetchUtils } from 'react-admin';
import { remove as removeDiacritics } from 'diacritics'
import { GET_LIST, GET_ONE, GET_MANY, GET_MANY_REFERENCE, CREATE, UPDATE, DELETE } from 'react-admin';

/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 * @example
 * GET_LIST     => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
    /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The REST request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const convertRESTRequestToHTTP = (type, resource, params) => {
        let url = '';
        const options = {};
        switch (type) {
            case GET_LIST: {
                var query = {
                }
                if (params.filter) {
                    const newFilter = {
                        ...params.filter
                    }
                    if (params.filter.q) {
                        newFilter.q = removeDiacritics(params.filter.q);
                    }
                    query = {
                        ...query,
                        search: stringify(JSON.parse(JSON.stringify(newFilter))).replace(/=/g,':').replace(/&/g,',') 
                    }
                }
                if (params.pagination) {
                    const { page, perPage } = params.pagination;
                    query = {
                        ...query,
                        size: perPage,
                        page: page - 1
                    }
                }
                if (params.sort) {
                    const { field, order } = params.sort;
                    query = {
                        ...query,
                        sort: field+","+order,
                    };
                }
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                break;
            }
            case GET_MANY: {
                if (params.ids && params.ids.length>0) {
                    url = `${apiUrl}/${resource}/ids:${params.ids.join(',')}`;
                } else {
                    url = null;
                }                
                break;
            }
            case GET_ONE:
                url = `${apiUrl}/${resource}/${params.id}`;
                break;
            case GET_MANY_REFERENCE: {
                const { page, perPage } = params.pagination;
                const { field, order } = params.sort;
                const query = {
                    ...fetchUtils.flattenObject(params.filter),
                    [params.target]: params.id,
                    _sort: field,
                    _order: order,
                    _start: (page - 1) * perPage,
                    _end: page * perPage,
                };
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                break;
            }
            case UPDATE:
                if (params.method) {
                    url = `${apiUrl}/${resource}/${params.id}/${params.method}`;
                } else {
                    url = `${apiUrl}/${resource}/${params.id}`;
                }
                options.method = 'PUT';
                options.body = JSON.stringify(params.data);
                break;
            case CREATE:
                url = `${apiUrl}/${resource}`;
                options.method = 'POST';
                options.body = JSON.stringify(params.data);
                break;
            case DELETE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = 'DELETE';
                break;
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
        return { url, options };
    };

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The REST request params, depending on the type
     * @returns {Object} REST response
     */
    const convertHTTPResponseToREST = (response, type, resource, params) => {
        const { headers, json } = response; // eslint-disable-line
        switch (type) {
            case GET_LIST:
            case GET_MANY_REFERENCE:
                if (resource === 'config') {
                    return {
                        data: [ json ],
                        total: 1,
                    };
                } else {
                    if (json.page && json.page.totalElements) {
                        return {
                            data: json._embedded[resource+"ResourceList"],
                            total: json.page.totalElements,
                        };
                    } else {
                        return {
                            data: [],
                            json: json,
                            total: 0,
                        };        
                    }
                }
            case GET_MANY:
                return { data: json._embedded[resource+"ResourceList"] };
            case CREATE:
                return { data: { ...params.data, ...json, id: json.id } };
            case DELETE:
                return { data: { id: params.id } };
            default:
                return { data: json };
        }
    };

    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a REST response
     */
    return (type, resource, params) => {
        const { url, options } = convertRESTRequestToHTTP(
            type,
            resource,
            params
        );
        if (url) {
            return httpClient(url, options).then(response =>
                convertHTTPResponseToREST(response, type, resource, params)
            );    
        } else {
            return { data: [] };
        }
    };
};
