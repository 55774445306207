import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { showNotification, translate, Button, refreshView } from 'react-admin';
import RunNowIcon from '@material-ui/icons/Sync';
import CancelIcon from '@material-ui/icons/Cancel';
import { getConfig } from './config';
import { httpClient } from './restClient';

class RunNowButton extends Component {
    handleClick = () => {
        const { record, actionName, showNotification, refreshView } = this.props;

        httpClient(`${getConfig().API_URL}/adatFrissitesBeallitas/${record.id}/${actionName}`, { method: 'POST' })
            .then(() => {
                showNotification(`resources.adatFrissitesBeallitas.actions.${actionName}Ok`);
                setTimeout(refreshView, 1000);
            })
            .catch((e) => {
                console.error(e);
                showNotification(`resources.adatFrissitesBeallitas.actions.${actionName}Fail`, 'warning')
                setTimeout(refreshView, 1000);
            });
    };

    render() {
        const { actionName = '' } = this.props;
        return (
            <Button label={`resources.adatFrissitesBeallitas.actions.${actionName}`} onClick={this.handleClick}>
                <Fragment>
                    { actionName === 'synchronize' &&
                        <RunNowIcon />
                    }
                    { actionName !== 'synchronize' &&
                        <CancelIcon />
                    }
                </Fragment>
            </Button>
        );        
    }
}

export default connect(undefined, { showNotification, translate, refreshView })(RunNowButton);
