import React from 'react';
// eslint-disable-next-line
import { translate, Create, List, Edit, Datagrid, Toolbar, FileInput, FileField, TextField, EditButton, ReferenceArrayField, SingleFieldList, ReferenceField, ReferenceArrayInput, TextInput, SimpleForm, DisabledInput, required, Pagination, SaveButton } from 'react-admin';
import DeleteWithConfirmButton from './components/DeleteWithConfirmButton';
import TranslatedSelectArrayInput from './components/TranslatedSelectArrayInput';
import MyIcon from '@material-ui/icons/Group';
import ChipListField from './ChipListField';

export const GroupIcon = MyIcon;

const MyPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />


export const GroupList = (props) => (
    <List {...props} bulkActionButtons={false} exporter={false} pagination={<MyPagination/>} perPage={25}>
        <Datagrid>
            <TextField source="name" />
            <ReferenceArrayField perPage={1000} reference="permission" source="permissions">
                <SingleFieldList class="column">
                    <ChipListField source="name" translatePrefix="" />
                </SingleFieldList>
            </ReferenceArrayField>
            <EditButton source="" />
            <DeleteWithConfirmButton source="" />
      </Datagrid>
    </List>
);

const validateRequired = [required()];

export const GroupCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="name" validate={validateRequired} />
            <ReferenceArrayInput perPage={1000} source="permissions" reference="permission">
                <TranslatedSelectArrayInput optionText="name" />
            </ReferenceArrayInput>
        </SimpleForm>
    </Create>
);

const MyToolbar = ({undoable, ...props}) => (<Toolbar {...props}>
    <SaveButton />
</Toolbar>);

export const GroupEdit = (props) => (
    <Edit {...props} undoable={false}>
        <SimpleForm toolbar={<MyToolbar />}>
            <DisabledInput source="id" />
            <TextInput source="name" validate={validateRequired} />
            <ReferenceArrayInput perPage={1000} source="permissions" reference="permission" >
                <TranslatedSelectArrayInput optionText="name" />    
            </ReferenceArrayInput>
        </SimpleForm>
    </Edit>
);
