import firebase from "firebase/app";
import "firebase/auth";

const env = {
  API_URL: process.env.REACT_APP_API_URL,
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  }
}

const dev = {
  API_URL: "http://localhost:8080/api",
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  firebaseConfig: {
    apiKey: "AIzaSyANtYRfqnUzDH6vyvvfnA86nux-wGtKZOM",
    authDomain: "jb-sandorfalva-test.firebaseapp.com",
    projectId: "jb-sandorfalva-test",
    appId: "1:1069630943529:web:86f4e03d392b6c58728738",
  }
};

const staging = {
  API_URL: "https://sandorfalva-api-staging.ikron.hu/api",
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
  firebaseConfig: {
    apiKey: "AIzaSyANtYRfqnUzDH6vyvvfnA86nux-wGtKZOM",
    authDomain: "jb-sandorfalva-test.firebaseapp.com",
    projectId: "jb-sandorfalva-test",
    appId: "1:1069630943529:web:86f4e03d392b6c58728738",
  }
};

const prod = {
  API_URL: "https://sandorfalva-api.ikron.hu/api",
};

let config = null;

let firebaseApp = null;

export const initConfig = () => {
  let url = window.location.href;
  if (process.env.REACT_APP_API_URL) {
    config = env;
    config.env = "env"
  } else if (url.includes("localhost")) {
    config = dev;
    config.env = "dev";
  } else if (url.includes("staging")) {
    config = staging;
    config.env = "staging";
  } else {
    config = prod;
    config.env = "prod";
  }

  config = {
    ...config,
  }

  // Initialize Firebase
  firebaseApp = firebase.initializeApp(config.firebaseConfig);
}

export const getConfig = () => {
  return config;
}

export const getFirebase = () => {
  return firebaseApp;
}

if (config == null) {
  initConfig();
}