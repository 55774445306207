import React from 'react';
// eslint-disable-next-line
import { required, Pagination } from 'react-admin';
// eslint-disable-next-line
import { Create, List, Edit, Datagrid, TextField, TextInput, SimpleForm, Toolbar, DeleteButton, SaveButton, FileField, FileInput, EditButton } from 'react-admin';
import MyIcon from '@material-ui/icons/Photo';
import { validateRequired } from './validators';
import _ from 'lodash';

export const BejelentesTipusIcon = MyIcon;

const MyPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />

const SvgFile = (props) => {
    const data = _.get(props, "record." + props.source + ".data", null);
    const title = _.get(props, "record." + props.source + ".title", null);
    if (data) {
        return <img class="bejelentestipus-icon" alt={title} src={"data:image/svg+xml;base64," + data} />;
    } else {
        return null;
    }
}

const SvgPreview = (props) => {
    const data = _.get(props, "record.data", null);
    const type = _.get(props, "record.type", "image/jpeg");
    const preview = _.get(props, "record.rawFile.preview", null);
    const title = _.get(props, "record.title", null);
    let src =  _.get(props, "record.src", null);
    if (preview) {
        src = preview;
    } else if (data) {
        src = "data:" + type + ";base64," + data;
    } 
    if (src) {
        return (
            <img class="bejelentestipus-preview" alt={title} src={src} />
        );
    } else {
        return null;
    }
}

export const BejelentesTipusList = (props) => (
    <div class="BejelentesTipus">
        <List {...props} 
            bulkActionButtons={false} 
            exporter={false} 
            pagination={<MyPagination/>} 
            perPage={25}
            sort={{ field: 'search', order: 'ASC' }}
        >
            <Datagrid>
                <SvgFile source="svgFile" label="resources.bejelentesTipus.fields.ikon" sortable={false}/>
                <TextField source="nev" sortable={false} />
                <EditButton source="buttons" undoable={false} sortable={false} />
                <DeleteButton undoable={false} sortable={false} />
            </Datagrid>
        </List>
    </div>
);

export const BejelentesTipusCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="nev" validate={validateRequired} />
            <FileInput {...props} source="svgFile" label="resources.bejelentesTipus.fields.svg" maxFiles={1} accept=".svg">
                <SvgPreview {...props} />
			</FileInput>    
        </SimpleForm>
    </Create>
);

const MyToolbar = (props) => (<Toolbar {...props}>
    <SaveButton undoable={false}/>
</Toolbar>);

export const BejelentesTipusEdit = (props) => (
    <Edit undoable={false} {...props}>
        <SimpleForm toolbar={<MyToolbar />}>
            <TextInput source="nev" validate={validateRequired} />
            <FileInput {...props} source="svgFile" label="resources.bejelentesTipus.fields.svg" maxFiles={1} accept=".svg">
                <SvgPreview {...props} />
			</FileInput>    
        </SimpleForm>
    </Edit>
);