import React from 'react';
// eslint-disable-next-line
import MyTextField from './MyTextField';
// eslint-disable-next-line
import { List, Edit, Datagrid, TextField, EditButton, FunctionField , TextInput, SimpleForm, DisabledInput, Labeled, FormDataConsumer  } from 'react-admin';
import MyIcon from '@material-ui/icons/Sync';
import cronstrue from 'cronstrue/i18n';
import RunNowButton from  './runNowButton';

export const AdatFrissitesIcon = MyIcon;

const renderCron = (cronExpression) => {
    if (cronExpression) {
        let cron = '';    
        try {
            cron = cronstrue.toString(cronExpression, { locale: "hu", use24HourTimeFormat: true });      
        } catch (error) {
            cron = 'Hibás kifejezés';
        }
        return cron;    
    } else {
        return 'Nincs időzítés';
    }
}

export const AdatFrissitesList = (props) => (
    <List {...props} bulkActionButtons={false} exporter={false} perPage={25} sort={{ field: 'weight', order: 'ASC' }}>
        <Datagrid>
            <MyTextField source="kategoria" sortable={false} isTranslated />
            <FunctionField sortable={false} label="resources.adatFrissitesBeallitas.fields.schedule" render={record => renderCron(record.schedule) } />
            <TextField source="statusz" sortable={false}/>
            <TextField source="utolsoFutas" sortable={false}/>
            <TextField source="utolsoFutasIdo" sortable={false}/>
            <TextField source="kovetkezoFutas" sortable={false}/>
            <EditButton source=""/>
            <FormDataConsumer {...props}>
                {({ formData, ...rest }) => {
                        return (
                            <RunNowButton {...rest} 
                                record={formData} 
                                source="" 
                                actionName={formData.statusz !== 'RUNNING' ? 'synchronize' : 'cancel'} 
                            />
                        );
                    }  
                }
            </FormDataConsumer>
        </Datagrid>
    </List>
);

export const AdatFrissitesEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <DisabledInput source="id" />
            <DisabledInput source="kategoria" />
            <TextInput source="schedule" />
            <FormDataConsumer {...props}>
                {({ formData, ...rest }) => {
                        return (
                            <Labeled label='resources.adatFrissitesBeallitas.fields.schedule'>
                                <FunctionField                                     
                                    record={formData}                                         
                                    render={ x => renderCron(x.schedule) }
                                />
                            </Labeled> 
                        );
                    }  
                }
            </FormDataConsumer>
            <div>            
                <br/>              
                Beállítások: <a href="https://szit.hu/doku.php?id=oktatas:linux:id%C5%91z%C3%ADt%C3%A9s">https://szit.hu/doku.php?id=oktatas:linux:id%C5%91z%C3%ADt%C3%A9s</a>
            </div>
        </SimpleForm>
    </Edit>
);
