module.exports = {
    ra: {
        action: {
			add: 'Hozzáad',
            add_filter: 'Szűrő hozzáadása',
            back: 'Vissza',	
            bulk_actions: '1 elem kijelölve |||| %{smart_count} elem kijelölve',
            cancel: 'Mégsem',
            clear_input_value: 'Mező törlése',
            clone: 'Klónozás',
            confirm: 'Megerősítés',
            create: 'Új',
		    delete: 'Törlés',
            edit: 'Szerkeszt',
            export: 'Export',
            list: 'Lista',
            refresh: 'Frissít',
            remove: 'Eltávolít',
            remove_filter: 'Szűrő törlése',
            save: 'Mentés',
            search: 'Keresés',
            show: 'Megtekint',
            sort: 'Rendezés',
            undo: 'Visszavonás',
            export_pdf: 'PDF letöltés',
            export_zip: 'ZIP letöltés',
            export_turaterv: 'Túraterv letöltés'
        },
        boolean: {
            true: 'Igen',
            false: 'Nem',
        },
        page: {
            list: '%{name}',
            edit: '%{name} módosítása',
            show: '%{name} #%{id}',
            create: '%{name} létrehozása',
            delete: '%{name} törlése',
            dashboard: 'Irányítópult',
            not_found: 'Nem található',
            error: 'Hiba történt',
            loading: 'Betöltés...',
        },
        input: {
			autocomplete: {
				none: '',
			},
			references: {
                single_missing: '',
                all_missing: '',
                many_missing: '',
			},
            image: {
                upload_several: 'Húzzunk ide néhány fájlt a feltöltéshez, vagy kattintsunk a fájlok kiválasztásához.',
                upload_single: 'Húzzunk ide egy fájlt a feltöltéshez, vagy kattintsunk a fájl kiválasztáshoz.',
            },
			file: {
                upload_several: 'Húzzunk ide néhány fájlt a feltöltéshez, vagy kattintsunk a fájlok kiválasztásához.',
                upload_single: 'Húzzunk ide egy fájlt a feltöltéshez, vagy kattintsunk a fájl kiválasztáshoz.',				
			}
        },
        message: {
            yes: 'Igen',
            no: 'Nem',
            are_you_sure: 'Biztos benne?',
            about: 'Névjegy',
            not_found: 'Az oldal nem található.',
            bulk_delete_content:
                'Are you sure you want to delete this %{name}? |||| Are you sure you want to delete these %{smart_count} items?',
            bulk_delete_title:
                'Delete %{name} |||| Delete %{smart_count} %{name} items',
            delete_content: 'Biztosan törölni szeretné ezt az elemet?',
            delete_title: 'Törlés',
            details: 'Részletek',
            error: "A kérést nem lehet végrehajtani, mert hiba történt a végrehajtás közben.",
            invalid_form: 'Hibás űrlap mezők. Ellenőrizze a kitöltött adatokat.',
            loading: 'Egy pillanat és az oldal betöltődik...',

        },
        navigation: {
            no_results: 'Nincs egyetlen bejegyzés sem.',
            no_more_results: 'The page number %{page} is out of boundaries. Try the previous page.',
            page_out_of_boundaries: 'A megadott oldalszám (%{page}) nem elérhető',
            page_out_from_end: 'Az utolsó oldalnál tovább nem lehet lapozni',
            page_out_from_begin: 'Az első olalnál előrrébb nem lehet lapozni',
            page_range_info: '%{offsetBegin}-%{offsetEnd} a(z) %{total} darabból.',
            page_rows_per_page: 'Sorok száma:',
            next: 'Következő',
            prev: 'Előző',
        },
        auth: {
            username: 'Felhasználónév',
            email: 'Email',
            password: 'Jelszó',
            sign_in: 'Bejelentkezés',
            sign_in_error: 'Sikertelen bejelentkezés. Kérem, próbálja újra.',
            logout: 'Kijelentkezés',
        },
        notification: {
            updated: 'A bejegyzés módosítva',
            created: 'A bejegyzés létrehozva',
            deleted: 'A bejegyzés törölve',
            item_doesnt_exist: 'A bejegyzés nem létezik',
            http_error: 'Hiba történ a kommunikáció során',
            bad_item: 'Hibás bejegyzés',
            data_provider_error: 'Adatszolgáltató hiba. Ellenőrizze a böngésző naplóját.',
            canceled: 'Megszakítva...',
            logged_out: 'A munkamenet véget ért, ezért ki lett jelentkeztetve',
        },
        validation: {
            required: 'Kitöltendő',
            filerequired: 'Legalább egy fájl megadása szükséges',
            minLength: 'Legalább %{min} karakter hosszú legyen',
            maxLength: 'Nem lehet több, mint %{max} karakter hosszú legyen',
            minValue: 'Legalább %{min} legyen',
            maxValue: 'Nem lehet több, mint %{max} legyen',
            number: 'Szám legyen',
            email: 'Helyes email legyen',
            valtozo: 'Ismeretlen változók:',
        },
    },
    resources: {
        user: {
            name: 'Felhasználó |||| Felhasználók',
            fields: {
                username: 'Felhasználói név',
                password: 'Jelszó',
                roles: 'Funkció',
                groupId: 'Csoport',
                enabled: 'Engedélyezve?',
                test: 'Teszt',
                accessedOn: 'Hozzáférés',
                clientVersion: 'Verzió',
            },
            actions: {
                newDevice: 'Új tablet',
                newInvite: 'Első meghívó',
                newDeviceOk: 'Email elküldve az új tablet hozzáadásáról.',
                newDeviceFail: 'Nem sikerült az emailt elküldeni az új tablet hozzáadásáról.',
                testEmail: 'Email teszt',
            }
        },
        roles: {
            admin: 'Admin',
            user: 'Ügynök',
			apidoc: 'Api dokumentáció',
        },     
        config: {
            name: 'Konfiguráció',
			tabs: { 
                settings: 'Beállítások',
				dev: 'Fejlesztés',
				play: 'Google Play',
                app: 'Mobil akalmazás',
                email: 'Levelezés',
			},
            fields: {
                figyelmeztetesEmails: 'Figyelmeztetés email címek (FEJLESZTÉS)',
				rendelesAllapotNapok: 'Rendelés állapot megtarás (napok)',
				deltaCompressNapok: 'Delta tömörítés időszak (napok)',
				minVersion: 'A stabil működéshez szükséges minimális verzió a Google Play áruházban',
				latestVersion: 'Elérhető legújabb verzió a Google Play áruházban',
				updateURL: 'Play store update link',
                devicelogNapok: 'Eszköz napló megtartása (napok)',
                mapsLink: 'Térkép url sablon ({0}: szélességi fok, {1}: hosszúsági fok)',
                elfogadottHelyek: 'Közterületi bejelentésnek elfogadott helyek (pontosvesszővel elválasztva)',
                fromName: 'Feladó neve (összes email)',
                fromEmailAddress: 'Feladó email cím (összes email)',
                reportEmailAddresses: 'Közterületi bejelentés emailcímek (pontosvesszővel elválasztva)',
                // eslint-disable-next-line
                reportEmailSubject: 'Közterületi bejelentés email tárgy (használható változók: ${felhasznalo} ${tipus} ${cim} ${megjegyzes})',
                smtpHost: 'SMTP host',
                smtpPort: 'SMTP port',
                smtpUser: 'SMTP felhasználó',
                smtpPassword: 'STMP jelszó',
            }
        },     
        adatFrissitesBeallitas: {
            name: 'Időzített feladatok',
            fields: {
                kategoria: 'Kategória',
                schedule: 'Időzítés',
                statusz: 'Státusz',
                utolsoFutas: 'Utolsó futás',
                utolsoFutasIdo: 'Utolsó idő',
                kovetkezoFutas: 'Köv. futás',
            },
            kategoria: {
                cache: 'Gyorsítótár', 
				deltacompress: 'Delta tömörítés',
                cleanup: 'Takarítás',
                kozteruletibejelentes: 'Közterületi bejelentés',
            },
            actions: {
                synchronize: 'Futtatás',
                synchronizeOk: 'Sikeresen elindítva',
                synchronizeFail: 'Indítás hiba',
                cancel: 'Leállítás',
                cancelOk: 'Sikeresen leállítva',
                cancelFail: 'Leállítás hiba',
            }
        },
        naplo: {
            name: 'Napló',
            fields: {
                datum: 'Dátum',
                kategoria: 'Kategória',
                uzenet: 'Üzenet',
            },
            szint: {
                info: 'INFO', 
                warning: 'FIGYELMEZTETÉS',
				error: 'HIBA'
            },
            kategoria: {
                cache: 'Gyorsítótár',
				deltacompress: 'Delta tömörítés',
                cleanup: 'Takarítás',
                adatmigracio: 'Adatmigráció',
                email: 'Email küldés',
            },
        },
 		devicelog: {
            name: 'Eszköz napló',
            fields: {
                timestamp: 'Dátum',
				level: 'Szint',
				message: 'Üzenet',
				appVersion: 'App. verzió',
            },
			filter: {
                email: 'Email',
				username: 'Fehasználó',
				search: 'Keresés',
				from: 'Dátumtól (nap)',
				fromhour: 'Dátumtól (óra) óó:pp',
				to: 'Dátumig (nap)',
				tohour: 'Dátumig (óra) óó:pp',
			},
			level: {
				info: 'INFO',
				debug: 'DEBUG',
				warn: 'WARN',
				seperator: '-----------',
			}
        },
        group: {
            name: 'Csoport |||| Csoportok',
            fields: {
                name: 'Név',
                permissions: 'Jogosultságok',
                termekek: 'Termék szűrés',
                ugyfelek: 'Ügyfél szűrés',
                telephelyek: 'Telepehely szűrés',
                filters: 'Szűrés',
                filters2: 'Cikk keresés szűrők',
                turaterv: 'Túraterv',
                filter: 'Cikk keresés szűrők',
            },
        },
        bejelentesTipus: {
            name: 'Bejelentés típus |||| Bejelentés típusok',
            fields: {
                nev: 'Név',
                svg: 'Ikon',
                svgFile: 'Ikon (SVG fájl)',
                ikon: 'Ikon',
                buttons: '',
            },
        },
        latnivalo: {
            name: 'Látnivaló |||| Látnivalók',
            fields: {
                nev: 'Név',
                kepek: 'Képek',
                leiras: 'Leírás',
                pozicio_longitude: 'Hosszúság',
                pozicio_latitude: 'Szélesség',
                cim: 'Cím',
                buttons: '',
            },
            map: 'Térkép',
        },
        search: 'Keresés...',
    },
    actions: {
        started: 'Művelet folyamatban...',
        ok: 'A művelet sikeresen végrehajtva.',
        fail: 'Az művelet sikertelen.',
    },
    filter: {
        search: 'Keresés',
        from: 'Dátumtól (nap)',
        fromhour: 'Dátumtól (óra) óó:pp',
        to: 'Dátumig (nap)',
        tohour: 'Dátumig (óra) óó:pp',  
        all: 'Minden',
        true: 'Használható',     
        false: 'Nem használható',     
    },
    permission: {
        mobil: {
            latogatas: 'Látogatás',
            telefonos_rendeles: 'Telefonos rendelés',
            nyitas_es_zaras: 'Nyitás és zárás',
            sajat_boltok: "Saját boltok",
            tavollet: "Távollét",
            tartozas: "Tartozás",
            latogatas_osszes_bolt: "Látogatás - Összes bolt",
            latogatas_osszes_bolt_turatervnelkul: "Látogatás - Összes bolt - Túraterv nélkül",
            latogatas_uj_bolt: "Látogatás - Új bolt",
            latogatas_napcsere: "Látogatás - Napcsere",
            latogatas_turaterv: "Látogatás - Túraterv",
            latogatas_gdpr: "Látogatás - GDPR",
            bolt_torzsadat: "Bolt - Törzsadat",
            bolt_tartozas: "Bolt - Tartozás",
            bolt_forgalom: "Bolt - Forgalom",
            bolt_korabbi_rendeles: "Bolt - Korábbi rendelés",
            bolt_korabbi_visszaru: "Bolt - Korábbi visszárú",
            bolt_arbeallitas: "Bolt - Árbeállítás",
            bolt_visszaru: "Bolt - visszárú",
            bolt_fenykep: "Bolt - Fénykép",
            bolt_rendeles: "Bolt - Rendelés",
            bolt_jegyzet: "Bolt - Jegyzet",
            rendeles_akcio: "Rendelés - Akció",
            rendeles_promocio: "Rendelés - Promóció",
            rendeles_rabatt: "Rendelés - Rabatt",
            rendeles_elmaradtforgalom: "Rendelés - Elmaradt forgalom"
        },    
    },
    tagselector: {
        f1: 'Besorolás',
        f2: 'Kategória',
        f3: 'Érték',
    },
    termekinput: {
        mennyiseg: 'Mennyiség',
        search: 'Rabatt termék megadása, termék nevének kezdőbetűi (min 3 karakter)',
    }, 
    auth: {
        error: {
            auth_invalid_email: 'Érvénytelen email cím formátum.',
            auth_wrong_password: 'Hibás jelszó.',
        }
    },
};