import React from 'react';
import { Edit, NumberInput, Toolbar, SaveButton, TextInput, TabbedForm, FormTab, required } from 'react-admin';
import MyIcon from '@material-ui/icons/Settings';
import { withStyles } from '@material-ui/core/styles';
import { validateVersion, validateEmails, validateEmail, validateRequired } from './validators';

export const ConfigurationIcon = MyIcon;

const validateVariables = (variables) => (value, allValues, props) => {
	const exp = new RegExp("(?!\\${(" + variables.join('|') + ")})\\$[^\\s]*", "g"); 
	const match = value.match(exp);
	return match == null ? undefined : props.translate('ra.validation.valtozo') + " " + match.join(", ");
}

const validateSubject = [required(), validateVariables(["felhasznalo", "tipus", "megjegyzes", "cim"])];

const styles = {
    div75: {
        width: '75%',
    },
};

const CustomToolbar = props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

export const ConfigurationEdit = withStyles(styles)(({classes, ...props}) => (
    <Edit undoable={false} title="resources.config.name" {...props}>
		<TabbedForm redirect="edit" toolbar={<CustomToolbar />}>
			<FormTab label="resources.config.tabs.settings">
				<TextInput className={classes.div75} source="mapsLink" validate={validateRequired} />
			</FormTab>
			<FormTab label="resources.config.tabs.app">
				<TextInput className={classes.div75} source="elfogadottHelyek" validate={validateRequired}/>
			</FormTab>
			<FormTab label="resources.config.tabs.email">
				<TextInput className={classes.div75} source="fromName" />
				<TextInput className={classes.div75} source="fromEmailAddress" validate={validateEmail}/>
				<TextInput className={classes.div75} source="reportEmailAddresses" validate={validateEmails}/>
				<TextInput className={classes.div75} source="reportEmailSubject" validate={validateSubject}/>
				<TextInput className={classes.div75} source="smtpHost" />
				<NumberInput className={classes.div75} source="smtpPort" />
				<TextInput className={classes.div75} source="smtpUser" />
				<TextInput className={classes.div75} source="smtpPassword" type="password" />
			</FormTab>
			<FormTab label="resources.config.tabs.play">
				<TextInput className={classes.div75} source="minVersion" validate={validateVersion} />
				<TextInput className={classes.div75} source="latestVersion" validate={validateVersion} />
				<TextInput className={classes.div75} source="updateURL" />
			</FormTab>
			<FormTab label="resources.config.tabs.dev">
				<TextInput className={classes.div75} source="figyelmeztetesEmails" />
				<NumberInput source="deltaCompressNapok" />
				<NumberInput source="devicelogNapok" />
			</FormTab>
        </TabbedForm>
    </Edit>
));
