import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { translate } from 'react-admin';
import { pure, compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';

const styles = {
};

export const MyTextField = ({
    translate,
	isTranslated = false,
	defaultValue = null,
	classes = {},
	className = null,
    source,
	record = {},
	color = null,
	render = null,
    ...rest
}) => {
	const { resource } = rest;

	const wrapper = {
		display: 'flex',
		flexWrap: 'wrap'
	};

	const colorValue = get(record, color);
	if (colorValue) {
		if (colorValue==='#FFF') {
			wrapper.color = 'yellow';
			wrapper.backgroundColor = 'black';					
		} else {
			wrapper.color = colorValue;
		}
	}
	
	let value = null;
	if (render) {
		value = render(record);
	} else {
		value = get(record, source);
	}


	if (defaultValue) {
		if (value === null || value.length === 0 || !value.trim()) {
			value = defaultValue;
		}
	}

	if (value) {
		if (isTranslated) {
			var key = "resources." + resource + "." + source + "." + value.toLowerCase();
			const str = translate(key);
			return (
				<div style={wrapper}>{str}</div>
			);				
		} else {
			const str = value.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>');
			return (
				<div style={wrapper} dangerouslySetInnerHTML={{__html: str}} />
			);
		}
	} else {
		return (
			<div style={wrapper}></div>
		);								
	}
};

MyTextField.contextTypes = {
    translate: PropTypes.func,
};

MyTextField.propTypes = {
    record: PropTypes.object,
	color: PropTypes.string,
	className: PropTypes.string,
	isTranslated: PropTypes.bool,
	render: PropTypes.func,
	defaultValue: PropTypes.string,
};

MyTextField.displayName = 'MyTextField';

MyTextField.defaultProps = {
    classes: {},
    translate: x => x,
    isTranslated: false,
};

const PureMyTextField = compose(
    pure,
    withStyles(styles),
    translate,
)(MyTextField);

PureMyTextField.defaultProps = {
    addLabel: true,
};

export default PureMyTextField; 